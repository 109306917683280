/* 애니메이션 정의 */
@keyframes flash {
  0% {
    opacity: 1;
    background-color: transparent;
  }
  50% {
    opacity: 0.5;
    background-color: white; /* 깜빡거리는 효과를 위한 색상 */
  }
  100% {
    opacity: 1;
    background-color: transparent;
  }
}

/* 애니메이션을 적용할 요소 */
.flash {
  animation: flash 0.2s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* 클릭 방지 */
  z-index: 10; /* 최상위 레이어로 표시 */
}
